export const change_login=()=>{
    return {
        type:'CHANGE_LOGIN'
    }
}

export const change_logout=()=>{
    return {
        type:'CHANGE_LOGOUT'
    }
}