import './Background.css';
const Background=()=>{
    return(
        <div class="animation-wrapper">
        <div class="particle particle-1"></div>
        <div class="particle particle-2"></div>
        <div class="particle particle-3"></div>
        <div class="particle particle-4"></div>
        </div>
    )
}
export default Background;